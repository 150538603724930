<template>
  <div>
    <iframe
      sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
      style="width: 120px; height: 240px"
      marginwidth="0"
      marginheight="0"
      scrolling="no"
      frameborder="0"
      :src=recAdSource
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "AmazonShoppingAd",
  props: {
    recAdSource: String,
  },
};
</script>
