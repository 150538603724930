
let CharityLinksObject = [
    {
        link: "https://www.stjude.org/",
        alt: "St. Jude",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc-joDmGWl6fStbvO9_CYW7edftQE7hYRSaF7wEh8NSmfF8XycgIoj3I8Olp1PBch2pct5MCLw5RdMz45FukTqcEpc6XaOHe9qjKFy3VfdUwD42hWfQeybjC00k6eLwcCCzJhfRuFplydBb4i1BUlTGv=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://www.aspca.org/",
        alt: "ASPCA",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc9G3MCshvXdBB_hp1Td2tNvokiCxEHoIkHD769bm3WHLd3qa_Mm6CqEzaNtyilZGAF2Pwlw11ePKfmK0_G05j4cbeueGqhlmMEUUURr8Hm4yCOBJGfn0SVs28dkIMG9b2CtOmfuUROWuZeedUmM-50N=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://www.toysfortots.org/",
        alt: "Toys for Tots",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc8OITuioGkn6r9aRa7YiYcL62RsJhezx-rEoPOeAKJGwYTVAKGocf372Zd2D3eilMzyJEZuBNXxML8Q_p8OvDzfY8LMbSc6ooHbGi-hA1LwgvyjmdMnltmeHgRuESjKiZhLlPfTl45ohK8JkRd0P4kc=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://janegoodall.org/",
        alt: "Jane Goodall",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc8NgXnNF0LlktQDMq77S35XFUcLsH6TRVSGl0kKxBfT5GtbQFGEUwefPdarrUozzkCAkxAJhO1vmRT9ahVa8Fsb_4A1qnACWttEt-1L9GpEIgErZRk84uGkkiIZ1VW41ciDFuT19oA6bmfId2PecB01=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://thp.org/",
        alt: "the hunger project",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc_DPjOiL9WQvX9SzQ1M6UO584Fm2N9El7kUthAbepu8vp4jBXmJRUCKN6MnISdo-kYT3I-X6wg8DWh2rBdMQwuJbYbHz2JVj2UtxfQAlCig4UiJie3IhNEMp59nkSwfpw0BqO3enn3ScItop7pwxOgk=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://www.nwf.org/",
        alt: "national wildlife foundation",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc-g_rwfPrL8Vws-mZJGkT3MUMPMnEmfiKTQORIwunpTcn_gydduiO-3oRJSRaQTiX2123dOSrJu80LDGNEsm-e7K2C3XuUhN6KSRXuXq_oKnzHrzhle1fcwmE8W9uev-9kyO-VInltMZvHD1DCgOElK=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://afsp.org/",
        alt: "american foundation for suicide prevention",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc8rBwDRsSbnckE6QfDiK4wtWdWmGM8jW3Xg7WcdV5YAZfC0-JgyEZsi875ogj_3qAjO8pY_eDlKlCz9w3i3xCJT0Q3Pbgjhpmghd_spjjHiYd1-tOAY9bEfh0LvWg3vPGffKfz-vYfj42fNZvZxgpeP=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://www.womenforwomen.org/",
        alt: "women for women international",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc-GLUEW2ZUeNpk23B1H-Nq-alFCFb4w1Bb-MYECACdlLZEnkpRqI5do-wvC7mT9KCE2QZgjGhb9eIuxFeZ9u3WMyihz3_hEZZ1QwASRHcbT2ct79hUyrVdbed9NGBHafsLyaaG04xsZ4sTG-pDzoA3u=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://bgca.org/",
        alt: "boys and girls club of america",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc-eFz437bW_jLztAOMj05a2FStRYzD3759tJFct0n8Qf3ZKDFYKyJf-lJDuGCvOixJpgB-gzo_F1dEK-ofKmPyFtGjb-h82yfXJlBzJq3n4xxlasjELZdTrGmxupmQCZq3l3nEF577bsaLc7QllIhdg=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://www.nationalbreastcancer.org/",
        alt: "national breast cancer foundation",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc-BKjT5UVmTJofOs3IQCyAP8ME3ZvOVjUXVBXM_E2I6kNdL_BBUEJkDKMqSHT8NO21frIDt6fXip75ARaV61PsBQKN0C7iouPOUHlPZ-gdcgf5H8uL1HxrXVMkSelbw90g8DZPVZCXSt2NEPl3d7VtZ=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://innocenceproject.org/",
        alt: "The innocence project",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc9ABQJfgl0utIiW900sZtNaT7M4eFASmBg-EP4O9vBH6mlSsY0PAPBHnDymPUbthIIvOh1uKDTo2l-8YU2gfXe9U9XDTldHu11XiDacjrSPHkfW7vCqL0M69aZ--kdFxn5OoWN35FMbFIE0BAJvNVZy=w1518-h759-s-no?authuser=0"
    },
    {
        link: "https://sosatogether.org/",
        alt: "SOSA - Safe from Online Sexual Assault",
        img: "https://lh3.googleusercontent.com/pw/AIL4fc9i853Dnos2e0T6PDZL-TxLducR-ZsxtuhvO6vmyE1jNkf-V1ZfnSEPD0DA5IalanrmQCoHGJ6iInl3NuUcNIfRbDeXlWwPXAuNgxJrKZ2zHA0zg3lwzsoraqIDPoSK7gX_3pO1RMN-6n4fUg47e8d0=w1518-h759-s-no?authuser=0"
    },
]
  
  
export default CharityLinksObject;
