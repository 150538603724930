<template>
  <div class="floral-back row justify-content-center mb-5">
    <div class="col-10">
      <div class="row row-cols-1 row-cols-md-2 justify-content-around white-op-back">
        <div class="col">
          <h3>sign up for our newsletter</h3>
          <p>for news, updates, and exclusive patterns</p>
        </div>
        <div class="col">
          <form>
            <h5>
              <label for="fName" class="form-label">name</label>
              <input
                style="
                  background-color: rgba(255, 255, 255, 0.9);
                  border: 2px solid black;
                "
                type="text"
                class="form-control"
                name="fName"
                id="fName"
                placeholder="name"
                v-model="fName"
                required
              />
              <br />
              <label for="email" class="form-label">email address</label>
              <input
                style="
                  background-color: rgba(255, 255, 255, 0.9);
                  border: 2px solid black;
                "
                type="email"
                class="form-control"
                name="email"
                id="email"
                placeholder="name@example.com"
                v-model="eMail"
                required
              />

              <br />
              <div class="form-check">
                <input
                  style="
                    background-color: rgba(255, 255, 255, 0.9);
                    border: 2px solid black;
                  "
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  required
                />
                <label class="form-check-label" for="flexCheckDefault">
                  yes, I would like to subscribe to the scarlet crochet designs mailing
                  list.
                </label>
              </div>
            </h5>
            <br />
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button class="btn btn-dark mb-3" @click="joinMail">join</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PattorialDataService from "@/services/PattorialDataService";

export default {
  name: "MailCard",
  data() {
    return {
      fName: "",
      eMail: "",
      mailGuts: String,
    };
  },
  methods: {
    joinMail() {
      let data = {
        fName: this.fName,
        email: this.eMail,
      };

      PattorialDataService.addToMail(data)
        .then((response) => {
          if (response.statusCode == 200) {
            this.mailGuts = "success";
            alert("thanks!");
          } else {
            this.mailGuts = "failed";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
