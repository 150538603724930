
import http from "../http-common";

class InputDataService {
  getPattorials() {
    return http.get("/pattorials");
  }

  getPatterns() {
    return http.get("/patterns");
  }

  get(id) {
    return http.get(`/pattorials/${id}`);
  }

  findBySeriesType(seriesType) {
    return http.get(`/pattorials?seriesType=${seriesType}`);
  }

  findByPatternType(patternType) {
    return http.get(`/patterns?patternType=${patternType}`);
  }
  findPattorialsByGroup(group) {
    return http.get(`/pattorialsByGroup?group=${group}`);
  }

  findPattorialsBySeriesId(group, id) {
    return http.get(`/pattorialsBySI?group=${group}&id=${id}`);
  }

  findPatternBySeriesId(group, id) {
    return http.get(`/patternsBySI?group=${group}&id=${id}`);
  }

  findPatternByIdNumber(group, id, number) {
    return http.get(`/patternsByIN?group=${group}&id=${id}&number=${number}`);
  }


  addToMail(data) {
    return http.post('/addToMail', data);
  }


}

export default new InputDataService();
