<script setup>
import { RouterLink, RouterView } from "vue-router";
</script>

<template>
  <div class="shopping-list">
    <p>
      <button
        class="btn btn-outline-danger"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRightWithBothOptions"
        aria-controls="offcanvasRightWithBothOptions"
      >
        <i class="fa-solid fa-bag-shopping fa-2xl" style="color: #ff2400"></i>
      </button>
    </p>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRightWithBothOptions"
      aria-labelledby="offcanvasRightWithBothOptionsLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightWithBothOptionsLabel">
          Shopping List
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <p>
          Disclosure: I am an Amazon Associate and I may earn a commission from purchases
          made through this website with my affiliate link.
        </p>
        <h5>Recommended Yarns</h5>
        <div class="row row-cols-3">
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B09W1HY4YP&asins=B09W1HY4YP&linkId=c7257c802549374263f5436fd9987afd&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0C448JY95&asins=B0C448JY95&linkId=b7a42b9dddd3f6d5b28a93ffee97c2d7&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B09W1KBWCT&asins=B09W1KBWCT&linkId=d828adf4cbe592eb832c887e2e444144&show_border=true&link_opens_in_new_window=true"
            />
          </div>

          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B07PZDDF4R&asins=B07PZDDF4R&linkId=3e950975487ab94d59f1709e31214726&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B07S6BNTXP&asins=B07S6BNTXP&linkId=1f477c3408f8d4af5e62950842bc9054&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B07PXMBG39&asins=B07PXMBG39&linkId=af9368dd38689a7d44af54e028198b1b&show_border=true&link_opens_in_new_window=true"
            />
          </div>

          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0BP9N2FP9&asins=B0BP9N2FP9&linkId=87e44095305eacf757267855c42d1076&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0BPB73QSJ&asins=B0BPB73QSJ&linkId=98159419ea7cc65e4ba756a1d485032d&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0C4TF8J9Y&asins=B0C4TF8J9Y&linkId=a78ac64719cd64f3c647772b6a6f42eb&show_border=true&link_opens_in_new_window=true"
            />
          </div>
        </div>
        <h5>Recommended Hooks</h5>
        <div class="row row-cols-3">
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B08HVX8PBX&asins=B08HVX8PBX&linkId=15ff1d4f3adb27c901db81c6a408dafb&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B09Q64FPMR&asins=B09Q64FPMR&linkId=709c50c4c2c69564f9d1e60c134e49fe&show_border=true&link_opens_in_new_window=true"
            />
          </div>
        </div>
        <h5>Extras</h5>
        <div class="row row-cols-3">
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0033UNIQC&asins=B0033UNIQC&linkId=8bd048aa651d73d4cd40df262d307170&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B009MGKQ0W&asins=B009MGKQ0W&linkId=a7ab5715fd7efb844d9930c9b6e9cddd&show_border=true&link_opens_in_new_window=true"
            />
          </div>
          <div class="col">
            <AmazonShoppingAd
              recAdSource="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=scarletcroche-20&language=en_US&marketplace=amazon&region=US&placement=B0BHZV7CY1&asins=B0BHZV7CY1&linkId=ff4408e4a4361031bc4e10be630efd3e&show_border=true&link_opens_in_new_window=true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="home" class="container sc-content white-op-back">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <!-- adspace -->
        <div class="d-flex row justify-content-center align-items-center my-4 ad-space">
          <div class="col-4 offset-md-1">
            <h1>Please consider donating to one of our favorite charities:</h1>
          </div>
          <div class="col-6">
            <a :href="randChar.link" target="_blank">
              <img
                :src="randChar.img"
                :alt="randChar.alt"
                style="margin: auto; max-width: 50%"
              />
            </a>
          </div>
        </div>

        <div class="row">
          <h1>
            <RouterLink to="/" style="color: black">scarlet crochet designs</RouterLink>
          </h1>
          <hr />
        </div>
        <div class="row">
          <div
            class="collapse show menu-list"
            style="text-decoration: none"
            id="menuListName"
          >
            menu
          </div>
        </div>
        <div class="row justify-content-center menu-button">
          <button
            @click="changeMenuButton"
            id="menuButton"
            class="btn btn-link"
            style="text-decoration: none; color: black"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".menu-list"
            aria-expanded="false"
            aria-controls="menuListName menuListLinks"
          >
            +
          </button>
        </div>

        <div class="d-flex row justify-content-center">
          <div class="d-flex col col-10 col-md-6 col-lg-3 text-start">
            <div class="collapse menu-list" id="menuListLinks">
              <ul style="list-style-type: none">
                <li>
                  <RouterLink to="/lrn/001">Learn Crochet</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/lrn/001/1">Getting Started</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/lrn/001/2">7 Basic Stitches</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/lrn/001/3">The Practice Tutorial pt.1</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/lrn/001/4">The Practice Tutorial pt.2</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/lrn/001/5">The Practice Tutorial pt.3</RouterLink>
                </li>
                <li class="indent">
                  <!-- <RouterLink to="/coming-soon"> -->
                  More Stuff2Know<em>-coming soon</em>
                  <!-- </RouterLink> -->
                </li>
                <li class="indent">
                  <!-- <RouterLink to="/coming-soon"> -->
                  Stitch Reference<em>-coming soon</em>
                  <!-- </RouterLink> -->
                </li>
              </ul>
            </div>
          </div>

          <div class="d-flex col col-10 col-md-6 col-lg-3 text-start">
            <div class="collapse menu-list" id="menuListLinks">
              <ul style="list-style-type: none">
                <li>
                  <!-- <RouterLink to="/fps/001"> -->
                  Your First Patterns
                </li>
                <!-- </RouterLink> -->
                <li class="indent">
                  <RouterLink to="/fps/001">First Five</RouterLink>
                </li>
                <li class="indent">
                  <!-- <RouterLink to="/coming-soon"> -->
                  Second Five<em>-coming soon</em>
                  <!-- </RouterLink> -->
                </li>
                <br />
                <li>
                  <!-- <RouterLink to="/sip/001/1"> -->
                  Featured Patterns
                  <!-- </RouterLink> -->
                </li>
                <li class="indent">
                  <RouterLink to="/sip/002/1">Little Punkins</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/sip/001/1">Fuzzy Scrunchie</RouterLink>
                </li>
                <!-- <li class="indent"><RouterLink to="/coming-soon">Fashion <em>-coming soon</em> </RouterLink></li>
            <li class="indent"><RouterLink to="/coming-soon">Home <em>-coming soon</em> </RouterLink></li>
            <li class="indent"><RouterLink to="/coming-soon">Baby <em>-coming soon</em> </RouterLink></li> -->
              </ul>
            </div>
          </div>

          <div class="d-flex col col-10 col-md-6 col-lg-3 text-start">
            <div class="collapse menu-list" id="menuListLinks">
              <ul style="list-style-type: none">
                <li>
                  <RouterLink to="/mon">Monthly Pattern Series</RouterLink>
                </li>
                <li class="indent">
                  <!-- <RouterLink to="/"> -->
                  new patterns coming 2024
                  <!-- </RouterLink> -->
                </li>
                <li><hr style="border-top: 1px dashed; width: 80%" /></li>
                <li class="indent">
                  <RouterLink to="/mon/001">November</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/mon/002"> December </RouterLink>
                </li>
              </ul>
            </div>
          </div>

          <div class="d-flex col col-10 col-md-6 col-lg-3 text-start">
            <div class="collapse menu-list" id="menuListLinks">
              <ul style="list-style-type: none">
                <li><RouterLink to="/about-me">About Me</RouterLink></li>
                <br />
                <li><RouterLink to="/legal-stuff/contest">Legal Stuff</RouterLink></li>
                <li class="indent">
                  <RouterLink to="/legal-stuff/privacy">Privacy Policy</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/legal-stuff/cookies">Cookies Policy</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/legal-stuff/contest">Contest Rules</RouterLink>
                </li>
                <li class="indent">
                  <RouterLink to="/legal-stuff/permissions">Terms of Use</RouterLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <!-- mail list popup -->
        <div class="modal-dialog modal-dialog-centered">
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <MailListPopUp />
              </div>
            </div>
          </div>
        </div>
        <!-- ml popup -->
        <div class="d-flex row connect">
          <div class="d-flex col justify-content-around">
            <ul>
              <li>
                <i
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  class="fa-regular fa-paper-plane fa-2xl showPointer"
                  style="color: #99c1f1"
                ></i>
              </li>
              <li>
                <a href="https://www.instagram.com/scarletcrochet/" target="_blank"
                  ><i class="fa-brands fa-instagram fa-2xl" style="color: #c705aa"></i
                ></a>
              </li>
              <li>
                <a href="https://www.pinterest.com/scarletcrochetdesigns/" target="_blank"
                  ><i class="fa-brands fa-pinterest fa-2xl" style="color: #e01b24"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://reddit.com/u/scarlet-crochet/s/Os3yvSYGFo"
                  target="_blank"
                  ><i class="fa-brands fa-reddit fa-2xl" style="color: #e66100"></i
                ></a>
              </li>
              <li>
                <a href="https://www.snapchat.com/add/scaretcrochet" target="_blank"
                  ><i class="fa-brands fa-snapchat fa-2xl" style="color: #000000"></i
                ></a>
              </li>
              <li>
                <a href="mailto:contact@scarletcrochetdesigns.com" target="_blank"
                  ><i class="fa-solid fa-envelope fa-2xl" style="color: #245fff"></i
                ></a>
              </li>
              <li>
                <!-- The text field -->
                <input
                  type="text"
                  value="https://scarletcrochetdesigns.com"
                  id="homeURL"
                  class="hidden"
                />
                <i
                  @click="copyLink"
                  class="fa-solid fa-link fa-2xl showPointer"
                  style="color: #cf153d"
                ></i>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <RouterView :key="$route.fullPath" />

    <hr />
    <div class="row justify-content-center legcon">
      <div class="col text-center">
        <br />
        <p>&copy;2023</p>
        <p>
          <a
            href="#"
            onclick="window.displayPreferenceModal();return false;"
            id="termly-consent-preferences"
            >Consent Preferences</a
          >
        </p>
        <p><RouterLink to="/legal-stuff/contest">legal stuff</RouterLink></p>
        <p><a href="mailto:contact@scarletcrochetdesigns.com">contact us</a></p>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import AmazonShoppingAd from "./components/ads/AmazonShoppingAd.vue";
import MailListPopUp from "./components/cards/MailListPopUp.vue";

import CharityLinks from "@/services/CharityLinks";

export default {
  name: "App",
  components: {
    AmazonShoppingAd,
    MailListPopUp,
  },
  data() {
    return {
      charityLinksArray: [],
      randChar: {},
    };
  },
  methods: {
    changeMenuButton() {
      let inH = document.getElementById("menuButton");

      if (inH.innerHTML === "x") {
        inH.innerHTML = "+";
      } else {
        inH.innerHTML = "x";
      }
    },

    copyLink() {
      // Get the text field
      var copyText = document.getElementById("homeURL");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
      alert("Copied the text: " + copyText.value);
    },

    getCharityLinks() {
      this.charityLinksArray = CharityLinks;

      let randoNum = Math.floor(Math.random() * this.charityLinksArray.length);
      this.randChar = this.charityLinksArray[randoNum];
    },
  },
  mounted() {
    this.getCharityLinks();
  },
};
</script>

<style>
@import url(../public/css/styles.css);
</style>
