<template>
  <div id="mail-list-pu">
    <div class="row justify-content-start">
      <div class="col offset-9">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>

    <MailCard />
  </div>
</template>

<script>
import MailCard from "./MailCard.vue";

export default {
  name: "MailListPopUp",
  components: {
    MailCard,
  },
};
</script>

<style>
#mail-list-pu {
  border: 1px black solid;
  background-color: palevioletred;
}
</style>
