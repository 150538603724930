/* eslint-disable no-unused-vars */
import { createWebHistory, createRouter } from "vue-router";

const routes =  [

  {
    path: "/",
    name: "home-view",
    component: () => import("./components/HomeView")
  },

  {
    path: "/sip/001/1",
    name:"single-pattern-fuzzy",
    component: () => import("./components/FuzzyScrunchie.vue")

  },
  {
    path: "/sip/001/2",
    name:"single-pattern-not-fuzzy",
    component: () => import("./components/NotFuzzyScrunchie.vue")

  },

  {
    path:"/lrn/001/1",
    component: () => import("./components/GettingStarted.vue")
  },

{
  path: "/:group",
  name: "pattorial-table",
  component: () => import("./components/PattorialToC.vue")
},


  {
    path: "/:group/:seriesId",
    name: "pattern-table-of-contents",
    component: () => import("./components/PatternToC.vue")
  },

  {
    path: "/:group/:seriesId/:patternNumber",
    name: "single-pattern",
    component: () => import("./components/SinglePattern.vue")
    
  },
  
  {
    path: "/about-me",
    name: "about-me",
    component: () => import("./components/About.vue")
  },
  {
    path: "/legal-stuff/privacy",
    name: "legal-stuff-privacy",
    component: () => import("./components/guts/PriNo.vue")
  },
  {
    path: "/legal-stuff/contest",
    name: "legal-stuff-contest",
    component: () => import("./components/guts/ConRule.vue")
  },
  {
    path: "/legal-stuff/cookies",
    name: "legal-stuff-cookies",
    component: () => import("./components/guts/CooPo.vue")
  },
  {
    path: "/legal-stuff/permissions",
    name: "legal-stuff-permissions",
    component: () => import("./components/guts/PermS.vue")
  },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
     // always scroll 10px above the element #main
    return { 
      el: '#main',
      top: -10 
    }
  },
  
});

export default router;
